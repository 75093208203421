.date-range-picker {
  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDateRangeWrapper {
    width: 100%;
  }

  .rdrMonth {
    padding: 8px;
    width: auto;
  }

  .rdrWeekDay {
    width: 28px !important;
  }

  .rdrDateDisplayWrapper {
    background: transparent;
  }

  .rdrDateDisplayWrapper::after,
  .rdrMonths::after {
    content: '';
    border-bottom: 1px solid #edeff5;
    display: block;
    width: 100%;
  }

  .rdrDateDisplayItem {
    box-shadow: none;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
  }

  .rdrStartEdge,
  .rdrInRange,
  .rdrEndEdge {
    background: var(--color-primary-0);
  }

  .rdrStartEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfMonth .rdrEndEdge {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rdrEndEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfMonth .rdrEndEdge {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rdrStartEdge ~ .rdrDayNumber span,
  .rdrInRange ~ .rdrDayNumber span,
  .rdrEndEdge ~ .rdrDayNumber span {
    color: var(--color-grey-10) !important;
  }

  .rdrDayToday .rdrDayNumber span::after {
    background: var(--color-primary) !important;
  }

  .rdrDateDisplayItemActive {
    border-color: var(--color-primary);
  }

  .rdrMonthPicker,
  .rdrYearPicker {
    margin: 0;
  }

  .rdrMonthPicker select,
  .rdrYearPicker select {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-primary);
    background: none;
    padding: 0;
    margin: 0;
  }

  .rdrMonthPicker select option,
  .rdrYearPicker select option {
    color: var(--color-grey-10);
  }

  .rdrNextPrevButton {
    background: none;
    position: relative;
    width: 24px;
    height: 24px;
  }

  .rdrPprevButton::after {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 2px solid #cbbcff;
    border-left: 2px solid #cbbcff;
    rotate: -45deg;
    transform-origin: 0% 0%;
  }

  .rdrNextButton::after {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 2px solid #cbbcff;
    border-left: 2px solid #cbbcff;
    rotate: 135deg;
    transform-origin: 0% 0%;
  }

  .rdrNextPrevButton:hover::after {
    border-color: var(--color-primary);
  }

  .rdrNextPrevButton i {
    display: none;
  }
}

@media (max-width: 540px) {
  .rdrMonth {
    width: 100%;
  }
}
