.tooltip-chip-bottom {
  @apply bg-white text-white rounded-md font-normal text-xs top-[30px] leading-[18px] py-2 px-4;
  transform: translate(-43%, 10%);
}

.tooltip-chip-bottom-arrow {
  &:after {
    @apply content-[''] absolute left-[44%]  top-[-14px] rotate-180 border-x-[10px] border-t-[12px] border-transparent border-t-white;
  }
}
