.checkbox:checked ~ .checkbox-icon {
  opacity: 100;
}

.checkbox:checked:disabled ~ .checkbox-icon {
  opacity: 100;
}

.checkbox:disabled ~ .checkbox-icon {
  opacity: 0;
}
